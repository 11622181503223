import Button from 'components/atoms/button'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import { getRegionalLink, isJapanese } from '../../helpers/locale'
import {
  ContentfulLinkContentfulRegionalLinkUnion,
  Maybe,
} from '../../types/graphql-types'
import Link from '../atoms/link'

const Items = styled.div<{ type?: 'card' }>`
  ${({ type, theme }): CSSProp => css`
    margin-left: -25%;
    margin-right: -25%;
    position: relative;
    display: flex;
    .slick-slider {
      max-width: 29rem;
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        max-width: 42.5rem;
      }
      ${type !== 'card' &&
        css`
          max-width: 42.5rem;
        `}
    }
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      padding: 3rem 0;
    }
    .slick-prev {
      display: none !important;
    }
    .slick-next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  `}
`

const Item = styled.div`
  ${({ theme }): CSSProp => css`
    padding: 0 2rem 0 0;
    outline: none;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      padding-right: 3rem;
    }
    .gatsby-image-wrapper {
      margin-bottom: 1.6rem;
    }
    a {
      text-decoration: none;
    }
    .title {
      font-weight: ${theme.font.fontWeightLight};
    }
  `}
`

const Card = styled(Link)`
  ${({ theme }): CSSProp => css`
    outline: none;
    text-decoration: none;
    position: relative;
    box-shadow: 0 5px 0 0 rgba(43, 25, 22, 0);
    transition: all 0.3s ease-in-out;
    .card-inner {
      padding: 2rem;
    }
    width: 33%;
    &::after {
      content: '';
      top: 0;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 5px;
      box-shadow: 0 5px 21px 0 rgba(43, 25, 22, 0.12);
      transition: opacity 0.3s ease-in-out;
    }
    &:hover {
      &::after {
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          opacity: 1;
        }
      }
      .gatsby-image-wrapper img {
        transform: scale(1);
      }
    }
    .gatsby-image-wrapper {
      margin-bottom: 1.6rem;
    }
    .subtitle {
      font-size: ${theme.font.fontSizeBodyMobile};
      font-weight: 300;
    }
    .title.custom-title {
      font-family: ${theme.font.fontFamilyDefault};
      font-size: ${theme.font.fontSizeBodyDefault};
      margin-bottom: 1rem;
      text-align: center;
      font-weight: ${theme.font.fontWeightBold};
    }
    .card-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    .product-slider-button {
      display: block;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  `}
`

interface ProductSliderProps {
  id: string
  products: Array<{
    id: string
    image: {
      fluid: FluidObject
      title: string
    }
    title: string
    regionallink: {
      title: string
      nzSlug: string
      auSlug: string
      ukSlug: string
      sgSlug: string
      jpSlug: string
    }
  }>
}

const ProductSlider: React.FC<ProductSliderProps> = ({ id, products }) => {
  return (
    <Items>
      {products.slice(0, 3).map(product => (
        <Card key={product.id} to={getRegionalLink(product.regionallink)}>
          <div className="card-inner">
            <div>
              <Img
                fluid={[{ ...product.image.fluid, aspectRatio: 1.5 }]}
                alt={product.title}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div className="title custom-title">{product.title}</div>
            <Link
              className="product-slider-button"
              to={getRegionalLink(product.regionallink)}
            >
              <Button>{product.regionallink.title}</Button>
            </Link>
          </div>
        </Card>
      ))}
    </Items>
  )
}

export default ProductSlider
